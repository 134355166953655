:root {
    --datan-green: #00b794;
    --datan-blue: #00668e;
    --datan-grey: #4c4c4c;
    --datan-orange: #ff661a;
    --datan-white: #f3f5f6;
    --datan-black: #212529;
    --datan-yellow: #fb9b00;
    --datan-red: #C5283D;
    --datan-grey-dark: #4D5755;

    --header-height: 50px;
    --h-btn-ctn: calc(20vw - 40px);
    color: var(--datan-black);
    background-color: var(--datan-white);
    font-family: "Open Sans";
    --buttons-height: calc( clamp(75px, var(--h-btn-ctn), 180px)) ;
}

a {
    text-decoration: none;
}

.center-body {
    display: grid;
    grid-template-columns: auto minmax(0, 800px) auto;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
        ". body .";
}

.body {
    grid-area: body;
}

.buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    flex-grow: 10;
    height: var(--buttons-height);
    pointer-events: none;
}

.buttons > .body {
    pointer-events: none;
}

.buttons > .body > * {
    pointer-events: auto;
}

.cursor-pointer {
    cursor: pointer;
}

.flex {
    display: flex;
    flex: 1 1 0;
    flex-flow: row wrap;
}

.column {
    flex-direction: column;
}

.flex-static {
    flex: 0 0 auto;
}

.align-justify-center {
    align-content: center;
    justify-content: center;
}

.text-center {
    text-align: center;
}


.no-decoration {
    text-decoration: none;
}

.datan-blue-bg {
    background-color: var(--datan-blue);
    color: rgba(255, 255, 255, 0.90);
}

.datan-green-bg {
    background-color: var(--datan-green);
    color: rgba(255, 255, 255, 0.90);
}

.margin {
    margin: 8px 10px;
}

.round-corner {
    border-radius: 5px;
}

.shadow {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    position: relative;
}

.shadow-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.shadow-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.shadow-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.shadow-5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--header-height);
    backdrop-filter: blur(3px);
    background-color: var(--datan-white);
    box-shadow: 0 2px 10px -2px rgb(0 0 0 / 10%);
}

.opacity-hidden {
    opacity: 0;
}

.pattern_background {
    background-color: #F3F5F6;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2300B794' fill-opacity='0.2' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
