.red {
    color: red
}

.button {
    color: var(--datan-white);
    height: 50px;
    max-width: 140px;
    cursor: pointer;
    border-radius: 7px;
    font-size: 1.1em;
    font-weight: 800;
    margin: 0px 8px;
    transition: 0.3s;
    max-width: 240px;
    text-align: center;
}

.button:hover {
    filter: brightness(0.92);
}