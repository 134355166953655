.basis-auto {
  flex: 1 1 auto;
}

.button {
  color: var(--datan-white);
  height: 50px;
  max-width: 140px;
  cursor: pointer;
  border-radius: 7px;
  font-size: 1.1em;
  font-weight: 800;
  margin: 0px 8px;
  transition: 0.3s;
}

.button:hover {
  filter: brightness(0.92);
}

.title-container {
  color: var(--datan-white);
  display: flex;
  justify-content: space-between;
  height: calc(100vh - var(--header-height) - var(--buttons-height));
  margin-bottom: var(--buttons-height);
  font-weight: 800;
  font-size: calc(20px + 0.5vw + 0.5vh);
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /*see: https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser*/
  height: 90%;
}

.title {
  text-align: center;
}

.explication {
  text-align: center;
  font-weight: 600;
  font-size: 0.6em;
}


.contre {
  background-color: var(--datan-red)
}

.pour {
  background-color: var(--datan-green)
}

.osef {
  background-color: var(--datan-yellow)
}