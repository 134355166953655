.surtitre {
  color: var(--datan-grey);
  font-size: 42px;
  text-align: center;
}

.titre {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 30px;
    text-align: center;
    color: var(--datan-grey);
}

h1 {
  margin: 0;
}

.sousTitre{
  font-size: 20px;
}

.text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
}
