.header {
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.res-depute-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.profile-container {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-container {
  display: flex;
  justify-content: space-around;
  align-items: start;
  flex-wrap: wrap;
}

.stats-pie-container {
  display: flex;
  margin: 0 2rem;
  margin-top: 3rem;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 1.25rem;
  border-radius: 10px;
  border: 1.5px solid var(--datan-green);
}

.explanation-card {
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  margin: 0 2rem;
  margin-top: 3rem;
  /*background-color:  rgb(0, 183, 148, 0.2);*/
  /*padding: 1.5rem;*/
  max-width: 600px;
}

.explanation-card div {
  margin-bottom: 0.5rem;
  font-size: 1em;
}

.link-container {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
}

.datan-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

.datan-link img {
  display: fex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.share-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 300px;
}

.share-btn-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.share-btn-container button {
  margin: 0.2rem;
  padding: 0.3rem 0.6rem;
  text-align: center;
  font-weight: 800;
  border-radius: 0.25rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
}

.share-btn-container button img {
  height: 20px;
  width: auto;
}

.share-btn-container button span {
  font-size: 0.85rem;
  margin-left: 0.25rem;
}

.twitter {
  background-color: #1DA1F2;
}

.facebook {
  background-color: #4267B2;
}

.linkedin {
  background-color: #2867B2;
}

.whatsapp {
  background-color: #128C7E;
}

.profile {
  width: 400;
  height: 125;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.depute-img-circle {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(#99BDDA, #fff);
  text-align: center;
}

.depute-img-circle img {
  width: 80%;
  height: auto;
}

.res-depute {
  --margin: 35px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 8%);
  width: calc(370px - 2*var(--margin));
  margin: 10px var(--margin)
}

.picture-container {
  display: flex;
  align-items: center;
}

.data-container {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  flex: 1 1 auto;
}

.data-container .title {
  color: #4D5755;
  line-height: 41px;
  font-size: 30px;
  font-weight: 700;
}

.data-container .groupe {
  line-height: 22px;
  font-size: 16px;
  font-weight: 700;
}

.data-container .district {
  color: #4D5755;
  font-size: 16px;
}

.res-depute .badge,
.res-groupe .badge {
  position: absolute;
  bottom: -13px;
  right: -19px;
  background-color: var(--datan-green);
  color: rgba(255, 255, 255, 0.92);
  padding: 0.4em 0.6em;
  border-radius: 0.25rem;
  font-weight: 800;
  line-height: 1;
}

a {
  color: inherit;
}

.res-groupe-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.groupe-img-circle {
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  background: linear-gradient(#99BDDA, #fff);
  text-align: center;
}

.groupe-img-circle img {
  width: 100%;
  height: auto;
}

.res-groupe {
  --margin: 35px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  padding: 10px 10px;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 8%);
  width: calc(370px - 2*var(--margin));
  margin: 10px var(--margin)
}

.search-input,
.search-input:focus,
.search-input:focus-visible {
  --margin: 35px;
  width: calc(100% - 2*var(--margin));
  height: 30px;
  margin: var(--margin);
  padding: 6px 8px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.34);
  color: var(--datan-black);
  background-color: var(--datan-white);
  z-index: 999;
  position: sticky;
  top: 85px;
}

.search-input:focus-visible {
  outline: 0;
  border: 1px solid rgba(0, 183, 148, 0.8);
}

.cards-container {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card{
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: #ffffff;
}

.card-title{
  color: #4C4C4C;
  font-weight: 600;
  font-size: 1em;
}

.button {
  color: var(--datan-white);
  height: 35px;
  width: 100px;
  border-radius: 7px;
}

.contre {
  background-color: var(--datan-red)
}

.pour {
  background-color: var(--datan-green)
}

.osef {
  background-color: var(--datan-yellow)
}

.mobileShow{
  display: none;
}

@media only screen and (max-width: 767.98px) {
  .mobileShow {display: block}
}
