.header {
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.res-depute-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.depute-img-circle {
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  background: linear-gradient(#99BDDA, #fff);
  text-align: center;
}

.depute-img-circle img {
  width: 80%;
  height: auto;
}

.res-depute {
  --margin: 35px;
  --padding-x: 10px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  padding: 10px var(--padding-x);
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);
  width: calc(min(370px, 100vw) - 2*var(--margin) - 2*var(--padding-x));
  margin: 10px var(--margin);
  background-color: #ffffff;
}

.data-container {
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1 1 auto;
}

.data-container .title {
  text-align: center;
  color: #4D5755;
  font-size: 1.5em;
  line-height: 1.2;
  font-weight: 800;
}

.data-container .groupe {
  text-align: center;
  font-size: 1.2em;
  font-weight: 600;
}

.res-depute .badge,
.res-groupe .badge {
  position: absolute;
  bottom: -13px;
  right: -19px;
  background-color: var(--datan-green);
  color: rgba(255, 255, 255, 0.92);
  padding: 0.4em 0.6em;
  border-radius: 0.25rem;
  font-weight: 800;
  line-height: 1;
}

a {
  color: inherit;
}

.res-groupe-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.groupe-img-circle {
  width: 75px;
  height: 75px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  background: linear-gradient(#99BDDA, #fff);
  text-align: center;
}

.groupe-img-circle img {
  width: 100%;
  height: auto;
}

.res-groupe {
  --margin: 35px;
  --padding-x: 10px;
  position: relative;

  display: flex;
  justify-content: space-evenly;
  padding: 10px var(--padding-x);
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);
  width: calc(min(370px, 100vw) - 2*var(--margin) - 2*var(--padding-x));
  margin: 10px var(--margin);
  background-color: #ffffff;
}

.button {
  color: var(--datan-white);
  height: 50px;
  max-width: 140px;
  cursor: pointer;
  border-radius: 7px;
  font-size: 1.1em;
  font-weight: 800;
  margin: 0px 8px;
  transition: 0.3s;
  max-width: 240px;
  text-align: center;
}

.button:hover{
  filter: brightness(0.92);
}

.search-container{
  background-color: var(--datan-blue);
  position: sticky;
  top: var(--header-height);
  z-index: 999;
  display: flex;
  flex-direction: column;
}

.search-input, .search-input:focus, .search-input:focus-visible {
  width: 100%;
  height: 30px;
  padding: 6px 8px;
  margin: 0 35px;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.34);
  border-radius: 10px;
  color: var(--datan-black);
  background-color: var(--datan-white);
}

.search-input:focus-visible {
  outline: 0;
  border: 1px solid rgba(0, 183, 148, 0.8);
}
