.button {
    color: var(--datan-white);
    height: 50px;
    max-width: 140px;
    cursor: pointer;
    border-radius: 7px;
    font-size: 1.1em;
    font-weight: 800;
    margin: 0px 8px;
    transition: 0.3s;
    max-width: 240px;
    text-align: center;
}

.button:hover {
    filter: brightness(0.92);
}

.title {
  color: var(--datan-green);
  margin-bottom: 30px;
}

.algorithm h2 {
  font-weight: 800;
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 30px;
  color: #4C4C4C;
}

.algorithm h3 {
  font-weight: 800;
  font-size: 16px;
  color: var(--datan-green);
}
